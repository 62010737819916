import React, { useState } from "react"
import E from "../ExcelForm.style"

const SectionOne = ({ allFormData, setTheFormData }) => {
  const [allData, setAllData] = useState({})
  const [boxOne, setBoxOne] = useState({})
  const [boxOneSum, setBoxOneSum] = useState(0)
  let boxOneItem = {}
  const [boxTwo, setBoxTwo] = useState({})
  const [boxTwoSum, setBoxTwoSum] = useState(0)
  let boxTwoItem = {}
  const [boxThree, setBoxThree] = useState({})
  const [boxThreeSum, setBoxThreeSum] = useState(0)
  let boxThreeItem = {}
  const [boxFour, setBoxFour] = useState({})
  const [boxFourSum, setBoxFourSum] = useState(0)
  let boxFourItem = {}
  const [boxFive, setBoxFive] = useState({})
  const [boxFiveSum, setBoxFiveSum] = useState(0)
  let boxFiveItem = {}
  const [boxSix, setBoxSix] = useState({})
  const [boxSixSum, setBoxSixSum] = useState(0)
  let boxSixItem = {}
  const [boxSeven, setBoxSeven] = useState({})
  const [boxSevenSum, setBoxSevenSum] = useState(0)
  let boxSevenItem = {}
  const [mainSum, setMainSum] = useState(0)

  const setBoxOneData = e => {
    let sum = 0
    setBoxOne({
      ...boxOne,
      [e.target.name]: e.target.value,
    })
    boxOneItem = { ...boxOne, [e.target.name]: e.target.value }
    for (const property in boxOneItem) {
      if (property !== "sum") {
        sum += Number(boxOneItem[property])
      }
    }
    boxOneItem = { ...boxOneItem, "סהכדיור": sum }
    setAllData({ ...allData, ...boxOneItem })
    let alldataObj = { ...allData, ...boxOneItem }
    const globalSum =
      sum +
      boxTwoSum +
      boxThreeSum +
      boxFourSum +
      boxFiveSum +
      boxSixSum +
      boxSevenSum
    setTheFormData({
      ...allFormData,
      "הוצאות חודשיות קבועות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxOneSum(sum)
    setMainSum(globalSum)
  }

  const setBoxTwoData = e => {
    let sum = 0
    setBoxTwo({
      ...boxTwo,
      [e.target.name]: e.target.value,
    })
    boxTwoItem = { ...boxTwo, [e.target.name]: e.target.value }
    for (const property in boxTwoItem) {
      if (property !== "sum") {
        sum += Number(boxTwoItem[property])
      }
    }
    boxTwoItem = { ...boxTwoItem, "סהכמזון": sum }
    setAllData({ ...allData, ...boxTwoItem })
    let alldataObj = { ...allData, ...boxTwoItem }
    const globalSum =
      boxOneSum +
      sum +
      boxThreeSum +
      boxFourSum +
      boxFiveSum +
      boxSixSum +
      boxSevenSum
    setTheFormData({
      ...allFormData,
      "הוצאות חודשיות קבועות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxTwoSum(sum)
    setMainSum(globalSum)
  }
  const setBoxThreeData = e => {
    let sum = 0
    setBoxThree({
      ...boxThree,
      [e.target.name]: e.target.value,
    })
    boxThreeItem = { ...boxThree, [e.target.name]: e.target.value }
    for (const property in boxThreeItem) {
      if (property !== "sum") {
        sum += Number(boxThreeItem[property])
      }
    }
    boxThreeItem = { ...boxThreeItem, "סהכילדים": sum }
    setAllData({ ...allData, ...boxThreeItem })
    let alldataObj = { ...allData, ...boxThreeItem }
    const globalSum =
      boxOneSum +
      boxTwoSum +
      sum +
      boxFourSum +
      boxFiveSum +
      boxSixSum +
      boxSevenSum
    setTheFormData({
      ...allFormData,
      "הוצאות חודשיות קבועות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxThreeSum(sum)
    setMainSum(globalSum)
  }
  const setBoxFourData = e => {
    let sum = 0
    setBoxFour({
      ...boxFour,
      [e.target.name]: e.target.value,
    })
    boxFourItem = { ...boxFour, [e.target.name]: e.target.value }
    for (const property in boxFourItem) {
      if (property !== "sum") {
        sum += Number(boxFourItem[property])
      }
    }
    boxFourItem = { ...boxFourItem, "סהכהלוואות": sum }
    setAllData({ ...allData, ...boxFourItem })
    let alldataObj = { ...allData, ...boxFourItem }
    const globalSum =
      boxOneSum +
      boxTwoSum +
      boxThreeSum +
      sum +
      boxFiveSum +
      boxSixSum +
      boxSevenSum
    setTheFormData({
      ...allFormData,
      "הוצאות חודשיות קבועות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxFourSum(sum)
    setMainSum(globalSum)
  }
  const setBoxFiveData = e => {
    let sum = 0
    setBoxFive({
      ...boxFive,
      [e.target.name]: e.target.value,
    })
    boxFiveItem = { ...boxFive, [e.target.name]: e.target.value }
    for (const property in boxFiveItem) {
      if (property !== "sum") {
        sum += Number(boxFiveItem[property])
      }
    }
    boxFiveItem = { ...boxFiveItem, "סהכתחבורה": sum }
    setAllData({ ...allData, ...boxFiveItem })
    let alldataObj = { ...allData, ...boxFiveItem }
    const globalSum =
      boxOneSum +
      boxTwoSum +
      boxThreeSum +
      boxFourSum +
      sum +
      boxSixSum +
      boxSevenSum
    setTheFormData({
      ...allFormData,
      "הוצאות חודשיות קבועות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxFiveSum(sum)
    setMainSum(globalSum)
  }
  const setBoxSixData = e => {
    let sum = 0
    setBoxSix({
      ...boxSix,
      [e.target.name]: e.target.value,
    })
    boxSixItem = { ...boxSix, [e.target.name]: e.target.value }
    for (const property in boxSixItem) {
      if (property !== "sum") {
        sum += Number(boxSixItem[property])
      }
    }
    boxSixItem = { ...boxSixItem, "סהכביטוחים": sum }
    setAllData({ ...allData, ...boxSixItem })
    let alldataObj = { ...allData, ...boxSixItem }
    const globalSum =
      boxOneSum +
      boxTwoSum +
      boxThreeSum +
      boxFourSum +
      boxFiveSum +
      sum +
      boxSevenSum
    setTheFormData({
      ...allFormData,
      "הוצאות חודשיות קבועות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxSixSum(sum)
    setMainSum(globalSum)
  }
  const setBoxSevenData = e => {
    let sum = 0
    setBoxSeven({
      ...boxSeven,
      [e.target.name]: e.target.value,
    })
    boxSevenItem = { ...boxSeven, [e.target.name]: e.target.value }
    for (const property in boxSevenItem) {
      if (property !== "sum") {
        sum += Number(boxSevenItem[property])
      }
    }
    boxSevenItem = { ...boxSevenItem, "סהכתקשורת": sum }
    setAllData({ ...allData, ...boxSevenItem })
    let alldataObj = { ...allData, ...boxSevenItem }
    const globalSum =
      boxOneSum +
      boxTwoSum +
      boxThreeSum +
      boxFourSum +
      boxFiveSum +
      boxSixSum +
      sum
    setTheFormData({
      ...allFormData,
      "הוצאות חודשיות קבועות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxSevenSum(sum)
    setMainSum(globalSum)
  }

  return (
    <E.FormBoxSection>
      <E.FormBoxSectionHeader>הוצאות חודשיות קבועות</E.FormBoxSectionHeader>
      <E.FormBox>
        <h3>דיור</h3>
        <label htmlFor="fname">
          משכנתא / שכ"ד:
          <input
            type="number"
            name="משכנתא/שכ''ד"
            id="fname"
            onChange={setBoxOneData}
          />
        </label>

        <label htmlFor="fname">
          ארנונה:
          <input
            type="number"
            name="ארנונה"
            id="fname"
            onChange={setBoxOneData}
          />
        </label>
        <label htmlFor="fname">
          מים:
          <input type="number" id="fname" name="מים" onChange={setBoxOneData} />
        </label>
        <label htmlFor="fname">
          גז:
          <input type="number" id="fname" name="גז" onChange={setBoxOneData} />
        </label>
        <label htmlFor="fname">
          חשמל:
          <input
            type="number"
            id="fname"
            name="חשמל"
            onChange={setBoxOneData}
          />
        </label>

        <label htmlFor="fname">
          ועד בית:
          <input
            type="number"
            id="fname"
            name="ועד בית"
            onChange={setBoxOneData}
          />
        </label>

        <label htmlFor="fname">
          עוזרת בית:
          <input
            type="number"
            id="fname"
            name="עוזרת בית"
            onChange={setBoxOneData}
          />
        </label>

        <span>{`סה"כ: ${boxOneSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>מזון וסופר פארם</h3>
        <label htmlFor="fname">
          סופר מרקט:
          <input
            type="number"
            id="fname"
            name="סופר מרקט"
            onChange={setBoxTwoData}
          />
        </label>

        <label htmlFor="fname">
          מאפיות , מכולת:
          <input
            type="number"
            id="fname"
            name="מאפיות , מכולת"
            onChange={setBoxTwoData}
          />
        </label>

        <label htmlFor="fname">
          סופר פארם:
          <input
            type="number"
            id="fname"
            name="סופר פארם"
            onChange={setBoxTwoData}
          />
        </label>

        <span>{`סה"כ: ${boxTwoSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>ילדים/נכדים</h3>
        <label htmlFor="fname">
          אוניברסיטה:
          <input
            type="number"
            id="fname"
            name="אוניברסיטה"
            onChange={setBoxThreeData}
          />
        </label>

        <label htmlFor="fname">
          תמיכה חודשית:
          <input
            type="number"
            id="fname"
            name="תמיכה חודשית"
            onChange={setBoxThreeData}
          />
        </label>

        <label htmlFor="fname">
          נכדים:
          <input
            type="number"
            id="fname"
            name="נכדים"
            onChange={setBoxThreeData}
          />
        </label>

        <span>{`סה"כ: ${boxThreeSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>הלוואות וחסכונות</h3>
        <label htmlFor="fname">
          הלוואה 1:
          <input
            type="number"
            id="fname"
            name="הלוואה 1"
            onChange={setBoxFourData}
          />
        </label>

        <label htmlFor="fname">
          הלוואה 2:
          <input
            type="number"
            id="fname"
            name="הלוואה 2"
            onChange={setBoxFourData}
          />
        </label>

        <label htmlFor="fname">
          חסכון חודשי:
          <input
            type="number"
            id="fname"
            name="חסכון חודשי"
            onChange={setBoxFourData}
          />
        </label>

        <span>{`סה"כ: ${boxFourSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>תחבורה ורכב</h3>
        <label htmlFor="fname">
          דלק:
          <input
            type="number"
            id="fname"
            name="דלק"
            onChange={setBoxFiveData}
          />
        </label>
        <label htmlFor="fname">
          תיקונים:
          <input
            type="number"
            id="fname"
            name="תיקונים"
            onChange={setBoxFiveData}
          />
        </label>

        <label htmlFor="fname">
          ביטוח רכב:
          <input
            type="number"
            id="fname"
            name="ביטוח רכב"
            onChange={setBoxFiveData}
          />
        </label>

        <label htmlFor="fname">
          טסט:
          <input
            type="number"
            id="fname"
            name="טסט"
            onChange={setBoxFiveData}
          />
        </label>

        <label htmlFor="fname">
          נסיעות:
          <input
            type="number"
            id="fname"
            name="נסיעות"
            onChange={setBoxFiveData}
          />
        </label>
        <label htmlFor="fname">
          אחר:
          <input
            type="number"
            id="fname"
            name="אחר"
            onChange={setBoxFiveData}
          />
        </label>
        <span>{`סה"כ: ${boxFiveSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>ביטוחים</h3>
        <label htmlFor="fname">
          ביטוחי חיים:
          <input
            type="number"
            id="fname"
            name="ביטוחי חיים"
            onChange={setBoxSixData}
          />
        </label>
        <label htmlFor="fname">
          ביטוחי בריאות וסיעוד:
          <input
            type="number"
            id="fname"
            name="ביטוחי בריאות וסיעוד"
            onChange={setBoxSixData}
          />
        </label>
        <label htmlFor="fname">
          ביטוח שיניים:
          <input
            type="number"
            id="fname"
            name="ביטוח שיניים"
            onChange={setBoxSixData}
          />
        </label>
        <label htmlFor="fname">
          קופ"ח משלים:
          <input
            type="number"
            id="fname"
            name="קופ''ח משלים"
            onChange={setBoxSixData}
          />
        </label>
        <label htmlFor="fname">
          ביטוח דירה:
          <input
            type="number"
            id="fname"
            name="ביטוח דירה"
            onChange={setBoxSixData}
          />
        </label>
        <label htmlFor="fname">
          קופות גמל:
          <input
            type="number"
            id="fname"
            name="קופות גמל"
            onChange={setBoxSixData}
          />
        </label>
        <span>{`סה"כ: ${boxSixSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>תקשורת</h3>
        <label htmlFor="fname">
          טלפון:
          <input
            type="number"
            id="fname"
            name="טלפון"
            onChange={setBoxSevenData}
          />
        </label>
        <label htmlFor="fname">
          טלפון נייד:
          <input
            type="number"
            id="fname"
            name="טלפון נייד"
            onChange={setBoxSevenData}
          />
        </label>
        <label htmlFor="fname">
          אינטרנט:
          <input
            type="number"
            id="fname"
            name="אינטרנט"
            onChange={setBoxSevenData}
          />
        </label>
        <label htmlFor="fname">
          כבלים / YES:
          <input
            type="number"
            id="fname"
            name="כבלים / YES"
            onChange={setBoxSevenData}
          />
        </label>
        <label htmlFor="fname">
          אגרת טלויזיה:
          <input
            type="number"
            id="fname"
            name="אגרת טלויזיה"
            onChange={setBoxSevenData}
          />
        </label>
        <span>{`סה"כ: ${boxSevenSum}`}</span>
      </E.FormBox>
      <E.FormBoxSectionSum>{`סה"כ הוצאות חודשיות קבועות: ${mainSum}`}</E.FormBoxSectionSum>
    </E.FormBoxSection>
  )
}

export default SectionOne
