import React, { useState } from "react"
import E from "../ExcelForm.style"

const SectionFour = ({ allFormData, setTheFormData }) => {
  const [allData, setAllData] = useState({})
  const [boxOne, setBoxOne] = useState({})
  const [boxOneSum, setBoxOneSum] = useState(0)
  let boxOneItem = {}
  const [boxTwo, setBoxTwo] = useState({})
  const [boxTwoSum, setBoxTwoSum] = useState(0)
  let boxTwoItem = {}
  const [mainSum, setMainSum] = useState(0)

  const setBoxOneData = e => {
    let sum = 0
    setBoxOne({
      ...boxOne,
      [e.target.name]: e.target.value,
    })
    boxOneItem = { ...boxOne, [e.target.name]: e.target.value }
    for (const property in boxOneItem) {
      if (property.indexOf("עלות") !== -1) {
        sum += Number(boxOneItem[property])
      }
    }
    boxOneItem = { ...boxOneItem, סהכדבריםלעצמנו: sum }
    setAllData({ ...allData, ...boxOneItem })
    let alldataObj = { ...allData, ...boxOneItem }
    const globalSum = sum + boxTwoSum
    setTheFormData({
      ...allFormData,
      "חלומות שלנו": { ...alldataObj, סהכ: globalSum },
    })
    setBoxOneSum(sum)
    setMainSum(globalSum)
  }
  const setBoxTwoData = e => {
    let sum = 0
    setBoxTwo({
      ...boxTwo,
      [e.target.name]: e.target.value,
    })
    boxTwoItem = { ...boxTwo, [e.target.name]: e.target.value }
    for (const property in boxTwoItem) {
      if (property.indexOf("עלות") !== -1) {
        sum += Number(boxTwoItem[property])
      }
    }
    boxTwoItem = { ...boxTwoItem, סהכיעדים: sum }
    setAllData({ ...allData, ...boxTwoItem })
    let alldataObj = { ...allData, ...boxTwoItem }
    const globalSum = boxOneSum + sum
    setTheFormData({
      ...allFormData,
      "חלומות שלנו": { ...alldataObj, סהכ: globalSum },
    })
    setBoxTwoSum(sum)
    setMainSum(globalSum)
  }

  return (
    <E.FormBoxSection>
      <E.FormBoxSectionHeader>החלומות שלנו</E.FormBoxSectionHeader>
      <E.FormBox>
        <h3>דברים שאנו רוצים עבור עצמינו</h3>
        <E.CostumLabel htmlFor="fname">אני רוצה להחליף דירה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בגיל:
            <input
              type="number"
              id="fname"
              name="שנים לקניית דירה"
              onChange={setBoxOneData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות:
            <input
              type="number"
              id="fname"
              name="עלות דירה"
              onChange={setBoxOneData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">אני רוצה לנסוע ברכב אחר:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בגיל:
            <input
              type="number"
              id="fname"
              name="קניית רכב בגיל"
              onChange={setBoxOneData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות:
            <input
              type="number"
              id="fname"
              name="עלות רכב"
              onChange={setBoxOneData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">לימודים באוניברסיטה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בגיל:
            <input
              type="number"
              id="fname"
              name="לימודים בגיל"
              onChange={setBoxOneData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות:
            <input
              type="number"
              id="fname"
              name="עלות לימודים"
              onChange={setBoxOneData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">לקנות יאכטה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בגיל:
            <input
              type="number"
              id="fname"
              name="לקנות יאכטה בגיל"
              onChange={setBoxOneData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות:
            <input
              type="number"
              id="fname"
              name="עלות יאכטה"
              onChange={setBoxOneData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">לעשות קורס סקיפרים:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בגיל:
            <input
              type="number"
              id="fname"
              name="לעשות קורס בגיל"
              onChange={setBoxOneData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות:
            <input
              type="number"
              id="fname"
              name="עלות קורס"
              onChange={setBoxOneData}
            />
          </label>
        </div>
        <span>{`סה"כ: ${boxOneSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>יעדים שאנו רוצים לבקר בהם</h3>
        <E.CostumLabel htmlFor="fname">יעד 1:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            מדינה:
            <input
              type="text"
              id="fname"
              name="יעד 1"
              onChange={setBoxTwoData}
              autoComplete="off"
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות:
            <input
              type="number"
              id="fname"
              name="עלות יעד 1"
              onChange={setBoxTwoData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">יעד 2:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            מדינה:
            <input
              type="text"
              id="fname"
              name="יעד 2"
              onChange={setBoxTwoData}
              autoComplete="off"
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות:
            <input
              type="number"
              id="fname"
              name="עלות יעד 2"
              onChange={setBoxTwoData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">יעד 3:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            מדינה:
            <input
              type="text"
              id="fname"
              name="יעד 3"
              onChange={setBoxTwoData}
              autoComplete="off"
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות:
            <input
              type="number"
              id="fname"
              name="עלות יעד 3"
              onChange={setBoxTwoData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">יעד 4:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            מדינה:
            <input
              type="text"
              id="fname"
              name="יעד 4"
              onChange={setBoxTwoData}
              autoComplete="off"
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות:
            <input
              type="number"
              id="fname"
              name="עלות יעד 4"
              onChange={setBoxTwoData}
            />
          </label>
        </div>
        <span>{`סה"כ: ${boxTwoSum}`}</span>
      </E.FormBox>
      <E.FormBoxSectionSum>{`סה"כ החלומות שלנו: ${mainSum}`}</E.FormBoxSectionSum>
    </E.FormBoxSection>
  )
}

export default SectionFour
