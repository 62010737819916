import React, { useState } from "react"
import E from "../ExcelForm.style"

const Contact = ({ allFormData, setTheFormData, formValid }) => {
  const [contactData, SetContactData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    mailingList: false,
  })

  const setLeadData = e => {
    SetContactData({
      ...contactData,
      [e.target.name]: e.target.value.toLowerCase(),
    })
    const contactDataObj = {
      ...contactData,
      [e.target.name]: e.target.value.toLowerCase(),
    }
    setTheFormData({ ...allFormData, contact: { ...contactDataObj } })
  }

  const setcheckboxData = e => {
    const InputChecked = e.target.checked
    SetContactData({ ...contactData, mailingList: InputChecked })
    const contactDataObj = { ...contactData, mailingList: InputChecked }
    setTheFormData({ ...allFormData, contact: { ...contactDataObj } })
  }

  return (
    <E.FormBoxSection>
      <E.FormBoxSectionHeader className="noAfter">
        פרטים אישיים
      </E.FormBoxSectionHeader>
      <E.FormBox className="contactBox">
        <p>
          לקבלת דוח מסכם עם התוצאות של היעדים הכלכליים שלך למייל, נא השאר את פרטייך:
        </p>
        <label htmlFor="fname">
          שם מלא: *
          <input
            type="text"
            id="fname"
            name="fullName"
            value={contactData.fullName}
            onChange={setLeadData}
            required
            autoComplete="off"
          />
        </label>
        <label htmlFor="fname">
          טלפון: *
          <input
            type="text"
            id="fname"
            name="phoneNumber"
            value={contactData.phoneNumber}
            onChange={setLeadData}
            required
            autoComplete="off"
          />
        </label>
        <label htmlFor="fname">
          מייל: *
          <input
            type="mail"
            id="fname"
            name="email"
            value={contactData.email}
            onChange={setLeadData}
            required
            autoComplete="off"
          />
        </label>
        <div className="mailingBox">
          <input
            value={contactData.mailingList}
            type="checkbox"
            id="mail"
            name="mailing"
            onChange={setcheckboxData}
          />
          <label htmlFor="mailing">
            אני מאשר את הוספת הפרטים שלי לרשימת התפוצה לטובת קבלת מידע
            ועידכונים.
          </label>
        </div>
        <E.ValidMessage>{formValid}</E.ValidMessage>
      </E.FormBox>
    </E.FormBoxSection>
  )
}

export default Contact
