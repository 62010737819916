import React, { useState } from "react"
import E from "../ExcelForm.style"

const SectionTwo = ({ allFormData, setTheFormData }) => {
  const [mainSum, setMainSum] = useState(0)
  const [allData, setAllData] = useState({})
  const [boxOne, setBoxOne] = useState({})
  const [boxOneSum, setBoxOneSum] = useState(0)
  const [boxTwo, setBoxTwo] = useState({})
  const [boxTwoSum, setBoxTwoSum] = useState(0)
  let boxTwoItem = {}
  const [boxThree, setBoxThree] = useState({})
  const [boxThreeSum, setBoxThreeSum] = useState(0)
  let boxThreeItem = {}
  const [boxFour, setBoxFour] = useState({})
  const [boxFourSum, setBoxFourSum] = useState(0)
  let boxFourItem = {}

  let boxOneItem = {}

  const setBoxOneData = e => {
    let sum = 0
    setBoxOne({
      ...boxOne,
      [e.target.name]: e.target.value,
    })
    boxOneItem = { ...boxOne, [e.target.name]: e.target.value }
    for (const property in boxOneItem) {
      if (property !== "sum") {
        sum += Number(boxOneItem[property])
      }
    }
      boxOneItem = { ...boxOneItem, "סהכפנאי": sum }
    setAllData({ ...allData, ...boxOneItem })
    let alldataObj = { ...allData, ...boxOneItem }
    const globalSum = sum + boxTwoSum + boxThreeSum + boxFourSum
    setTheFormData({
      ...allFormData,
      "הוצאות משתנות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxOneSum(sum)
    setMainSum(globalSum)
  }
  const setBoxTwoData = e => {
    let sum = 0
    setBoxTwo({
      ...boxOne,
      [e.target.name]: e.target.value,
    })
    boxTwoItem = { ...boxTwo, [e.target.name]: e.target.value }
    for (const property in boxTwoItem) {
      if (property !== "sum") {
        sum += Number(boxTwoItem[property])
      }
    }
       boxTwoItem = { ...boxTwoItem, "סהכתיירות": sum }
    setAllData({ ...allData, ...boxTwoItem })
    let alldataObj = { ...allData, ...boxTwoItem }
    const globalSum = boxOneSum + sum + boxThreeSum + boxFourSum
    setTheFormData({
      ...allFormData,
      "הוצאות משתנות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxTwoSum(sum)
    setMainSum(globalSum)
  }
  const setBoxThreeData = e => {
    let sum = 0
    setBoxThree({
      ...boxThree,
      [e.target.name]: e.target.value,
    })
    boxThreeItem = { ...boxThree, [e.target.name]: e.target.value }
    for (const property in boxThreeItem) {
      if (property !== "sum") {
        sum += Number(boxThreeItem[property])
      }
    }
    boxThreeItem = { ...boxThreeItem, "סהכביגוד": sum }
    setAllData({ ...allData, ...boxThreeItem })
    let alldataObj = { ...allData, ...boxThreeItem }
    const globalSum = boxOneSum + boxTwoSum + sum + boxFourSum
    setTheFormData({
      ...allFormData,
      "הוצאות משתנות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxThreeSum(sum)
    setMainSum(globalSum)
  }
  const setBoxFourData = e => {
    let sum = 0
    setBoxFour({
      ...boxFour,
      [e.target.name]: e.target.value,
    })
    boxFourItem = { ...boxFour, [e.target.name]: e.target.value }
    for (const property in boxFourItem) {
      if (property !== "sum") {
        sum += Number(boxFourItem[property])
      }
    }
        boxFourItem = { ...boxFourItem, "סהכשונות": sum }
    setAllData({ ...allData, ...boxFourItem })
    let alldataObj = { ...allData, ...boxFourItem }
    const globalSum = boxOneSum + boxTwoSum + boxThreeSum + sum
    setTheFormData({
      ...allFormData,
      "הוצאות משתנות": { ...alldataObj, סהכ: globalSum },
    })
    setBoxFourSum(sum)
    setMainSum(globalSum)
  }

  return (
    <E.FormBoxSection>
      <E.FormBoxSectionHeader>
        הוצאות משתנות - Life Style
      </E.FormBoxSectionHeader>
      <E.FormBox>
        <h3>פנאי ובילוי</h3>
        <label htmlFor="fname">
          מסעדות/ארוחות בוקר:
          <input
            type="number"
            id="fname"
            name="מסעדות/ארוחות בוקר"
            onChange={setBoxOneData}
          />
        </label>
        <label htmlFor="fname">
          הצגות:
          <input
            type="number"
            id="fname"
            name="הצגות"
            onChange={setBoxOneData}
          />
        </label>
        <label htmlFor="fname">
          סרטים:
          <input
            type="number"
            id="fname"
            name="סרטים"
            onChange={setBoxOneData}
          />
        </label>
        <label htmlFor="fname">
          אחר:
          <input
            type="number"
            id="fname"
            name="פנאיאחר"
            onChange={setBoxOneData}
          />
        </label>
        <span>{`סה"כ: ${boxOneSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>תיירות ונופש</h3>
        <label htmlFor="fname">
          חופשות בארץ:
          <input
            type="number"
            id="fname"
            name="חופשות בארץ"
            onChange={setBoxTwoData}
          />
        </label>
        <label htmlFor="fname">
          חופשה קבועה בחו"ל:
          <input
            type="number"
            id="fname"
            name="חופשה קבועה בחו''ל"
            onChange={setBoxTwoData}
          />
        </label>
        <label htmlFor="fname">
          אחר:
          <input type="number" id="fname" name="אחר" onChange={setBoxTwoData} />
        </label>
        <span>{`סה"כ: ${boxTwoSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>ביגוד, הנעלה,שופינג</h3>
        <label htmlFor="fname">
          ביגוד והנעלה:
          <input
            type="number"
            id="fname"
            name="ביגוד והנעלה"
            onChange={setBoxThreeData}
          />
        </label>
        <label htmlFor="fname">
          גאדג'טים:
          <input
            type="number"
            id="fname"
            name="גאדג'טים"
            onChange={setBoxThreeData}
          />
        </label>
        <span>{`סה"כ: ${boxThreeSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>שונות</h3>
        <label htmlFor="fname">
          קאנטרי / מכון כושר:
          <input
            type="number"
            id="fname"
            name="קאנטרי / מכון כושר"
            onChange={setBoxFourData}
          />
        </label>
        <label htmlFor="fname">
          מספרה / קוסמטיקה:
          <input
            type="number"
            id="fname"
            name="מספרה / קוסמטיקה"
            onChange={setBoxFourData}
          />
        </label>
        <label htmlFor="fname">
          אחזקת בית , גנן:
          <input
            type="number"
            id="fname"
            name="אחזקת בית , גנן"
            onChange={setBoxFourData}
          />
        </label>
        <label htmlFor="fname">
          הום סנטר / ACE:
          <input
            type="number"
            id="fname"
            name="הום סנטר / ACE"
            onChange={setBoxFourData}
          />
        </label>
        <label htmlFor="fname">
          סיגריות:
          <input
            type="number"
            id="fname"
            name="סיגריות"
            onChange={setBoxFourData}
          />
        </label>
        <label htmlFor="fname">
          ריפוי / תרופות:
          <input
            type="number"
            id="fname"
            name="ריפוי / תרופות"
            onChange={setBoxFourData}
          />
        </label>
        <label htmlFor="fname">
          וטרינר:
          <input
            type="number"
            id="fname"
            name="וטרינר"
            onChange={setBoxFourData}
          />
        </label>
        <label htmlFor="fname">
          תרומות:
          <input
            type="number"
            id="fname"
            name="תרומות"
            onChange={setBoxFourData}
          />
        </label>
        <span>{`סה"כ: ${boxFourSum}`}</span>
      </E.FormBox>
      <E.FormBoxSectionSum>{`סה"כ הוצאות משתנות: ${mainSum}`}</E.FormBoxSectionSum>
    </E.FormBoxSection>
  )
}

export default SectionTwo
