import React, { useState } from "react"
import E from "../ExcelForm.style"

const SectionThree = ({ allFormData, setTheFormData }) => {
  const [allData, setAllData] = useState({})
  const [boxOne, setBoxOne] = useState({})
  const [boxOneSum, setBoxOneSum] = useState(0)
  let boxOneItem = {}
  const [boxTwo, setBoxTwo] = useState({})
  const [boxTwoSum, setBoxTwoSum] = useState(0)
  let boxTwoItem = {}
  const [boxThree, setBoxThree] = useState({})
  const [boxThreeSum, setBoxThreeSum] = useState(0)
  let boxThreeItem = {}
  const [boxFour, setBoxFour] = useState({})
  const [boxFourSum, setBoxFourSum] = useState(0)
  let boxFourItem = {}
  const [mainSum, setMainSum] = useState(0)

  const setBoxOneData = e => {
    let sum = 0
    setBoxOne({
      ...boxOne,
      [e.target.name]: e.target.value,
    })
    boxOneItem = { ...boxOne, [e.target.name]: e.target.value }
    for (const property in boxOneItem) {
      if (property.indexOf("עלות") !== -1) {
        sum += Number(boxOneItem[property])
      }
    }
    boxOneItem = { ...boxOneItem, סהכילד1: sum }
    setAllData({ ...allData, "ילד 1": boxOneItem })
    let alldataObj = { ...allData, "ילד 1": boxOneItem }
    const globalSum = sum + boxTwoSum + boxThreeSum + boxFourSum
    setTheFormData({
      ...allFormData,
      "רצון להבטחת עתיד ילדינו": { ...alldataObj, סהכ: globalSum },
    })
    setBoxOneSum(sum)
    setMainSum(globalSum)
  }
  const setBoxTwoData = e => {
    let sum = 0
    setBoxTwo({
      ...boxTwo,
      [e.target.name]: e.target.value,
    })
    boxTwoItem = { ...boxTwo, [e.target.name]: e.target.value }
    for (const property in boxTwoItem) {
      if (property.indexOf("עלות") !== -1) {
        sum += Number(boxTwoItem[property])
      }
    }
    boxTwoItem = { ...boxTwoItem, סהכילד2: sum }
    setAllData({ ...allData, "ילד 2": boxTwoItem })
    let alldataObj = { ...allData, "ילד 2": boxTwoItem }
    const globalSum = boxOneSum + sum + boxThreeSum + boxFourSum
    setTheFormData({
      ...allFormData,
      "רצון להבטחת עתיד ילדינו": { ...alldataObj, סהכ: globalSum },
    })
    setBoxTwoSum(sum)
    setMainSum(globalSum)
  }

  const setBoxTreeData = e => {
    let sum = 0
    setBoxThree({
      ...boxThree,
      [e.target.name]: e.target.value,
    })
    boxThreeItem = { ...boxThree, [e.target.name]: e.target.value }
    for (const property in boxThreeItem) {
      if (property.indexOf("עלות") !== -1) {
        sum += Number(boxThreeItem[property])
      }
    }
    boxThreeItem = { ...boxThreeItem, סהכילד3: sum }
    setAllData({ ...allData, "ילד 3": boxThreeItem })
    let alldataObj = { ...allData, "ילד 3": boxThreeItem }
    const globalSum = boxOneSum + boxTwoSum + sum + boxFourSum
    setTheFormData({
      ...allFormData,
      "רצון להבטחת עתיד ילדינו": { ...alldataObj, סהכ: globalSum },
    })
    setBoxThreeSum(sum)
    setMainSum(globalSum)
  }
  const setBoxFourData = e => {
    let sum = 0
    setBoxFour({
      ...boxFour,
      [e.target.name]: e.target.value,
    })
    boxFourItem = { ...boxFour, [e.target.name]: e.target.value }
    for (const property in boxFourItem) {
      if (property.indexOf("עלות") !== -1) {
        sum += Number(boxFourItem[property])
      }
    }
    boxFourItem = { ...boxFourItem, סהכילד4: sum }
    setAllData({ ...allData, "ילד 4": boxFourItem })
    let alldataObj = { ...allData, "ילד 4": boxFourItem }
    const globalSum = boxOneSum + boxTwoSum + boxThreeSum + sum
    setTheFormData({
      ...allFormData,
      "רצון להבטחת עתיד ילדינו": { ...alldataObj, סהכ: globalSum },
    })
    setBoxFourSum(sum)
    setMainSum(globalSum)
  }

  return (
    <E.FormBoxSection>
      <E.FormBoxSectionHeader>רצון להבטחת עתיד ילדינו</E.FormBoxSectionHeader>
      <E.FormBox>
        <h3>ילד 1</h3>
        <E.CostumLabel htmlFor="fname">לימודים:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="לימודים בעוד שנים"
              onChange={setBoxOneData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות לימודים"
              onChange={setBoxOneData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">חתונה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="חתונה בעוד שנים"
              onChange={setBoxOneData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות חתונה"
              onChange={setBoxOneData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">דירה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="דירה בעוד שנים"
              onChange={setBoxOneData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות דירה"
              onChange={setBoxOneData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">תמיכה חודשית:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="תמיכה במספר שנים"
              onChange={setBoxOneData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות תמיכה"
              onChange={setBoxOneData}
            />
          </label>
        </div>
        <span>{`סה"כ: ${boxOneSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>ילד 2</h3>
        <E.CostumLabel htmlFor="fname">לימודים:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="לימודים בעוד שנים"
              onChange={setBoxTwoData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות לימודים"
              onChange={setBoxTwoData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">חתונה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="חתונה בעוד שנים"
              onChange={setBoxTwoData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות חתונה"
              onChange={setBoxTwoData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">דירה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="דירה בעוד שנים"
              onChange={setBoxTwoData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות דירה"
              onChange={setBoxTwoData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">תמיכה חודשית:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="תמיכה במספר שנים"
              onChange={setBoxTwoData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות תמיכה"
              onChange={setBoxTwoData}
            />
          </label>
        </div>
        <span>{`סה"כ: ${boxTwoSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>ילד 3</h3>
        <E.CostumLabel htmlFor="fname">לימודים:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="לימודים בעוד שנים"
              onChange={setBoxTreeData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות לימודים"
              onChange={setBoxTreeData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">חתונה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="חתונה בעוד שנים"
              onChange={setBoxTreeData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות חתונה"
              onChange={setBoxTreeData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">דירה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="דירה בעוד שנים"
              onChange={setBoxTreeData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות דירה"
              onChange={setBoxTreeData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">תמיכה חודשית:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="תמיכה במספר שנים"
              onChange={setBoxTreeData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות תמיכה"
              onChange={setBoxTreeData}
            />
          </label>
        </div>
        <span>{`סה"כ: ${boxThreeSum}`}</span>
      </E.FormBox>
      <E.FormBox>
        <h3>ילד 4</h3>
        <E.CostumLabel htmlFor="fname">לימודים:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="לימודים בעוד שנים"
              onChange={setBoxFourData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות לימודים"
              onChange={setBoxFourData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">חתונה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="חתונה בעוד שנים"
              onChange={setBoxFourData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות חתונה"
              onChange={setBoxFourData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">דירה:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="דירה בעוד שנים"
              onChange={setBoxFourData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות דירה"
              onChange={setBoxFourData}
            />
          </label>
        </div>
        <E.CostumLabel htmlFor="fname">תמיכה חודשית:</E.CostumLabel>
        <div className="row">
          <label className="littleLabel" htmlFor="fname">
            בעוד מס' שנים:
            <input
              type="number"
              id="fname"
              name="תמיכה במספר שנים"
              onChange={setBoxFourData}
            />
          </label>
          <label className="littleLabel" htmlFor="fname">
            עלות ח"פ:
            <input
              type="number"
              id="fname"
              name="עלות תמיכה"
              onChange={setBoxFourData}
            />
          </label>
        </div>
        <span>{`סה"כ: ${boxFourSum}`}</span>
      </E.FormBox>

      <E.FormBoxSectionSum>{`סה"כ רצון להבטחת עתיד ילדינו: ${mainSum}`}</E.FormBoxSectionSum>
    </E.FormBoxSection>
  )
}

export default SectionThree
