import React, { useState } from "react"
import E from "./ExcelForm.style"
import Button from "../Button"
import SectionOne from "./FormSections/SectionOne"
import SectionTwo from "./FormSections/SectionTwo"
import SectionThree from "./FormSections/SectionThree"
import SectionFour from "./FormSections/SectionFour"
import Contact from "./FormSections/Contact"
import { db } from "../../firebase"

// import { setDataState } from "./ExcelFormLogic"

const ExcelForm = () => {
  const [formData, setFormData] = useState({
    "הוצאות חודשיות קבועות": { סהכ: 0 },
    "הוצאות משתנות": { סהכ: 0 },
    "רצון להבטחת עתיד ילדינו": { סהכ: 0 },
    "חלומות שלנו": { סהכ: 0 },
    contact: {
      fullName: "",
      phoneNumber: "",
      email: "",
      mailingList: false,
    },
  })
  const [formSendMessage, SetFormSendMessage] = useState(false)
  const [formValid, SetFormValid] = useState("")

  const SubmitForm = e => {
    e.preventDefault()
    const phoneNumberValid = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm
    const mailValid = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i

    if (formData.contact.fullName === "") {
      SetFormValid("יש להזין שם מלא")
      return
    } else if (formData.contact.email === "") {
      SetFormValid("יש להזין כתובת מייל תקינה")
      return
    } else if (formData.contact.phoneNumber === "") {
      SetFormValid("יש להזין מספר טלפון תקין")
      return
    }

    if (
      formData.contact.fullName !== "" &&
      formData.contact.email !== "" &&
      formData.contact.phoneNumber !== ""
    ) {
      if (
        formData.contact.phoneNumber.length < 8 ||
        formData.contact.phoneNumber.length > 10 ||
        phoneNumberValid.test(formData.contact.phoneNumber) !== true
      ) {
        SetFormValid("יש להזין מספר טלפון תקין")
        return
      } else {
        if (
          formData.contact.email !== "" &&
          formData.contact.email.match(mailValid) === null
        ) {
          SetFormValid("יש להזין כתובת מייל תקינה")
          return
        }
        db.collection("Forms").add({
          formData,
        })
        SetFormValid("")
        SetFormSendMessage(true)
        setTimeout(() => {
          window.location.reload(false)
        }, 1000)
      }
    }
  }

  return (
    <E.Wrapper>
      <h1>הוצאות חודשיות וחד פעמיות צפויות</h1>
      <form>
        <SectionOne allFormData={formData} setTheFormData={setFormData} />
        <SectionTwo allFormData={formData} setTheFormData={setFormData} />
        <SectionThree allFormData={formData} setTheFormData={setFormData} />
        <SectionFour allFormData={formData} setTheFormData={setFormData} />
        <Contact
          formValid={formValid}
          allFormData={formData}
          setTheFormData={setFormData}
        />
        <Button onClick={SubmitForm}>שלח</Button>
        {formSendMessage ? <h1>הטופס נשלח בהצלחה!</h1> : ""}
      </form>
    </E.Wrapper>
  )
}

export default ExcelForm
