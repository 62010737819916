import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import Head from "../components/Head"
import styled from "styled-components"
import ExcelForm from "../components/ExcelForm"
import palette from "../components/GlobalStyle/globals/palette"

const E = {
  Root: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  `,

  ContentText: styled.div`
    overflow: hidden;
    z-index: 2;
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 40px 5px 40px 40px;
    padding: 30px;
    margin: 40px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    p {
      padding-bottom: 20px;
      &:last-of-type {
        padding-bottom: 40px;
      }
    }
    a {
      text-decoration: none;
      width: 100%;
      :hover {
        text-decoration: none;
        color: ${palette.primary.main};
      }
    }
  `,
}
const Economicgoals = () => {
  return (
    <Layout>
      <Seo title="יעדים כלכליים" />
      <Head title="מה היעדים הכלכלים שלך?" />
      <div className="container">
        <E.Root>
          <E.ContentText>
            <ExcelForm />
          </E.ContentText>
        </E.Root>
      </div>
    </Layout>
  )
}

export default Economicgoals
