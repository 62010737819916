import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"
import palette from "../GlobalStyle/globals/palette"

const E = {
  Wrapper: styled.div`
    width: 100%;

    h1 {
      text-align: center;
      width: 100%;
    }

    form {
      display: flex;
      flex-wrap: wrap;
    }

    button {
      margin: 0 auto;
    }
  `,
  FormBoxSection: styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 15px;
    border: 1px solid rgba(17, 65, 107, 0.3);
    border-radius: 10px;
    margin: 20px 0;

    @media (max-width: ${breakpoints.small}px) {
      justify-content: center;
      align-items: center;
    }
  `,
  FormBoxSectionSum: styled.span`
    display: block;
    width: 100%;
    text-align: center;
    background: rgba(242, 190, 52, 0.3);
    border-radius: 10px;
    margin-top: 10px;
    font-weight: bold;
  `,
  FormBoxSectionHeader: styled.span`
    position: absolute;
    top: -18px;
    right: 10px;
    font-size: 1.3rem;
    padding: 0 5px;
    background: #fff;
    &:after {
      content: '(בש"ח)';
      position: relative;
      display: inline-block;
      z-index: 3;
      padding-right: 3px;
    }
    &.noAfter {
      &:after {
        content: "";
      }
    }
    @media (max-width: ${breakpoints.small}px) {
      font-size: 1rem;
    }
  `,

  FormBox: styled.div`
    flex: 0 0 23%;
    margin: 10px 4px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-right: 2px dashed rgba(17, 65, 107, 0.2);
    padding-right: 5px;

    &.row {
      flex-direction: row;
    }

    &.contactBox {
      border: none;

      p {
        width: 100%;
        text-align: center;
      }
      label {
        width: auto;
        padding-left: 10px;
      }
      @media (min-width: ${breakpoints.large}px) {
        margin: 10px 4px;
        display: flex;
        flex: 0 0 100%;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;
        padding-top: 10px;

        label {
          width: auto;
          padding-left: 10px;
          &:after {
            content: "";
          }
        }
        input {
          margin-left: 10px;
        }
      }
      @media (max-width: ${breakpoints.large}px) {
        flex: 1 1 100%;
        p {
          text-align: right;
        }
      }
    }

    span {
      display: block;
      margin-top: auto;
      padding: 0 10px;
      border-radius: 10px;
      background: rgba(209, 222, 232, 0.4);
      font-weight: bold;
      text-align: center;
    }

    h3 {
      width: 100%;
      font-size: 1.1rem;
      position: relative;
      margin-bottom: 5px;
    }

    label {
      display: block;
      padding-bottom: 5px;
      width: 100%;
      &.littleLabel {
        display: inline-block;
        width: 50%;
      }
    }

    input {
      display: block;
      border-radius: 5px;
      margin-bottom: 5px;
      border: 1px solid rgba(17, 65, 107, 0.3);
      outline: none;
      padding: 2px;
      color: ${palette.primary.main};
      width: 100%;

      &:last-of-type {
        margin-bottom: 15px;
      }
      &:focus {
        border: 2px solid rgba(17, 65, 107, 0.6);
      }

      @media (max-width: ${breakpoints.small}px) {
        width: 100%;
      }
    }

    @media (max-width: ${breakpoints.large}px) {
      flex: 0 0 45%;
    }

    @media (max-width: ${breakpoints.medium}px) {
      flex: 1 1 100%;
      margin: 10px 0;
    }
    & .mailingBox {
      width: 100%;
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      @media (min-width: ${breakpoints.large}px) {
        flex: 0 0 60%;
      }
      label {
        width: 90%;
      }
      input {
        width: 10%;
        margin-top: 11px;
      }
    }
  `,

  CostumLabel: styled.h3`
    display: block;
    padding-bottom: 5px;
    width: 100%;
    font-weight: 400;
    font-size: 1rem;
  `,
  ValidMessage: styled.h3`
    width: 100%;
    text-align: center;
    padding: 10px 0;
  `,
}

export default E
